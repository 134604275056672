import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Home.vue')
    },
    {
        path: '/code',
        name: 'Code',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Code.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/myprofile",
        name: 'Myprofile',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Myprofile.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/users",
        name: 'Users',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Users.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Roles.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/modules",
        name: 'Modules',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Modules.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/pages",
        name: 'Pages',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Pages.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/dashboard",
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Dashboard.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/logs",
        name: 'Logs',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Logs.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/apikey",
        name: 'APIKEY',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/APIKEY.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/compliance",
        name: 'Compliance',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Compliance.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/scoring",
        name: 'Scoring',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Scoring.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/scoring_risk",
        name: 'Scoring Risk',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Scoring_Risk.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/internal",
        name: 'Internal',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Internal.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/external",
        name: 'External',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/External.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/analy_planning",
        name: 'Planning',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/AnalyPlanning.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/emonitoring",
        name: 'EMonitoring',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/EMonitoring.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/registration",
        name: 'Registration',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Registration.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/analysis",
        name: 'Analysis',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Analysis.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/planning",
        name: 'Risk Planning',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Planning.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/monitoring",
        name: 'Monitoring',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Monitoring.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/control",
        name: 'Control',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Control.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/gapanalysis",
        name: 'GAPAnalysis',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/GAPAnalysis.vue'),
        meta: {
            authRequired: true
        }
    },
    {
        path: "/group",
        name: 'Group',
        component: () => import(/* webpackChunkName: "Home" */ '../pages/Group.vue'),
        meta: {
            authRequired: true
        }
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});






export default router;
