<template>
	<div>
                <Sidebar v-model:visible="this.$store.state.loading" :baseZIndex="1000" position="full" style="background: transparent;">
                        <img class="loading" alt="Logo" src="images/boton.png"/>
                        <ProgressSpinner  style="width:100%;position: fixed;top: 50%;left: 50%;-webkit-transform: translate(-50%, -50%);"/>
		</Sidebar>
	</div>
</template>

<style>
.loading {
        width: 90px;
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
}

</style>